<template>
  <artmisads-modal
    :open="true"
    title="Bank Information"
    :width="720"
    :onCancel="() =>  props.setShowModal(false)"
    :destroyOnClose="true"
  >
    <a-spin :spinning="spinning">
      <div class="accountInfoModal">
        <div class="item">
          <a-form>
            <a-form-item
              name="accountName"
              v-bind="validateInfos.accountName"
            >
              <div class="input-item">
                <span class="text">Account Name</span>
                <artmisads-input disabled maxlength="100" style="width: 324px;" @blur="() => validate('accountName')" size="middle" v-model:value="modelRef.accountName"/>
              </div>
            </a-form-item>
          </a-form>
          <a-form>
            <a-form-item
              name="bankCountry"
              v-bind="validateInfos.bankCountry"
            >
              <div class="input-item">
                <span class="text">Bank Location Country<span>*</span></span>
                <artmisads-select
                  v-model:value="modelRef.bankCountry"
                  style="width: 324px;"
                  size="middle"
                  :options="props.countryList"
                />
              </div>
            </a-form-item>
          </a-form>
        </div>
        <div class="item">
          <a-form>
            <a-form-item
              name="bankAccountNumber"
              v-bind="validateInfos.bankAccountNumber"
            >
              <div class="input-item">
                <span class="text">Bank Account Number<span>*</span></span>
                <artmisads-input maxlength="50" style="width: 324px;" @blur="() => validate('bankAccountNumber')" size="middle" v-model:value="modelRef.bankAccountNumber"/>
              </div>
            </a-form-item>
          </a-form>
          <a-form>
            <a-form-item
              name="bankRountingNumber"
              v-bind="validateInfos.bankRountingNumber"
            >
              <div class="input-item">
                <span class="text">Bank Rounting Number<span>*</span></span>
                <artmisads-input maxlength="50" style="width: 324px;" @blur="() => validate('bankRountingNumber')" size="middle" v-model:value="modelRef.bankRountingNumber"/>
              </div>
            </a-form-item>
          </a-form>
        </div>
        <p class="note">(for Non-US bank, Please fill Swift number)</p>
      </div>
    </a-spin>
    <template #footer>
      <div class="btn">
        <artmisads-button type="primary" @click="publisherProfileSaveOrUpdate">Save</artmisads-button>
      </div>
    </template>
  </artmisads-modal>
</template>

<script setup>
import moment from 'moment';
import { Form } from 'ant-design-vue';
import { ref, reactive, onMounted } from "vue";
import { publisherApi } from "~/server";

const props = defineProps(["accountInfo", 'setShowModal', 'countryList']);
const spinning = ref(false);

const useForm = Form.useForm;
  const modelRef = reactive({
    accountName: props.accountInfo.accountName,
    bankCountry: props.accountInfo.bankCountry,
    bankAccountNumber: props.accountInfo.bankAccountNumber,
    bankRountingNumber: props.accountInfo.bankRountingNumber,
  });

  const rulesRef = reactive({
    accountName: [{
      required: false,
    }],
    bankCountry: [{
      required: true,
      message: 'Please fill in your bank location country',
      trigger: ['blur']
    }],
    bankAccountNumber: [{
      required: true,
      message: 'Please fill in your bank account number',
      trigger: ['blur']
    }],
    bankRountingNumber: [{
      required: true,
      message: 'Please fill in your bank rounting number',
      trigger: ['blur']
    }],
  });

  const { validate, validateInfos } = useForm(modelRef, rulesRef);

const publisherProfileSaveOrUpdate = async () => {
  const validateRes = await validate();
  if (!validateRes.errorFields) {
    spinning.value = true;
    let paramsData = validateRes;
    const res = await await publisherApi.publisherProfileSaveOrUpdate(paramsData);
    const { success } = res;
    if (success) {
      props.setShowModal(false, true);
    }
    spinning.value = false;
  }
};

</script>

<style lang="less" scoped>
.accountInfoModal {
  padding-bottom: 56px;
  .note {
    line-height: 18px;
    color: var(--dark-3);
    font-weight: 400;
    font-size: 12px;
    letter-spacing: 0.3px;
    margin-top: 8px;
  }
  .item {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
    &:first-child {
      margin-top: 8px;
    }
    :deep(.ant-form) {
      .input-item {
        display: flex;
        flex-direction: column;
        width: 324px;
        .text {
          color: var(--dark-2);
          font-weight: 500;
          line-height: 18px;
          font-size: 13px;
          margin-bottom: 4px;
          display: flex;
          > span {
            color: var(--red-text);
            margin-left: 3px;
            font-size: 14px;
            font-weight: 600;
            line-height: 18px;
          }
        }
      }
      .ant-form-item {
        margin-bottom: 0;
      }
    }
  }
}
</style>
