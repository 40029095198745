<template>
  <div class="account">
    <div class="head">
      <p> Bank Information<span v-if="!props.accountInfo.bankCountry">*</span></p>
      <artmisads-select-button @click="() => setShowModal(true)" size="middle" :showEditIcon="true">Update Bank Info</artmisads-select-button>
    </div>
    <div class="cont">
      <div class="item">
        <div class="item-left">
          <span class="name">Account Name:</span>
          <span class="text" v-if="props.accountInfo.accountName">{{props.accountInfo.accountName}}</span>
          <span class="no-data" v-else>--</span>
        </div>
        <div class="item-right">
          <span class="name">Bank Location Country:</span>
          <span class="text" v-if="props.accountInfo.bankCountry">{{props.countryListMap[props.accountInfo.bankCountry]?.label}}</span>
          <span class="no-data" v-else>--</span>
        </div>
      </div>
      <div class="item">
        <div class="item-left">
          <span class="name">Bank Account Number:</span>
          <span class="text" v-if="props.accountInfo.bankAccountNumber">{{props.accountInfo.bankAccountNumber}}</span>
          <span class="no-data" v-else>--</span>
        </div>
        <div class="item-right">
          <span class="name">Bank Rounting Number:</span>
          <span class="text" v-if="props.accountInfo.bankRountingNumber">{{props.accountInfo.bankRountingNumber}}</span>
          <span class="no-data" v-else>--</span>
        </div>
      </div>
    </div>
    <editAccountInfoModal
      :accountInfo="props.accountInfo"
      :setShowModal="setShowModal"
      :countryList="countryList"
      v-if="showModal"
    />
  </div>
</template>
<script setup>
  import { ref } from 'vue';
  import editAccountInfoModal from './editAccountInfoModal.vue';
  const props = defineProps(['accountInfo', 'getData', 'countryList', 'countryListMap'])

  const showModal = ref(false);

  const setShowModal = (status, reload) => {
    showModal.value = status;
    if (reload) {
      props.getData()
    }
  }

</script>
<style lang="less" scoped>
  .account {
    padding: 16px 0 24px 16px;
    overflow: hidden;
    border-radius: 12px;
    background-color: var(--color-white);
    box-shadow: var(--section-box-shadow-6);
    .head {
      padding: 0 16px 16px 0;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid var(--border-4);
      > p {
        font-weight: 600;
        font-size: 20px;
        color: var(--dark-1);
        letter-spacing: 0.5px;
        line-height: 28px;
        > span {
          color: var(--red-text);
          margin-left: 3px;
          font-size: 16px;
          font-weight: 600;
          line-height: 32px;
        }
      }
    }
    .cont {
      padding-right: 16px;
      .item {
        display: flex;
        margin-top: 24px;
      }
      .item-right {
        margin-left: 16px;
      }
      .item-right,
      .item-left {
        width: 50%;
        display: flex;
        flex-direction: column;
        .name {
          font-weight: 400;
          font-size: 13px;
          color: var(--dark-3);
          letter-spacing: 0.3px;
          line-height: 18px;
        }
        .text {
          font-weight: 600;
          font-size: 14px;
          color: var(--dark-1);
          letter-spacing: 0.4px;
          line-height: 20px;
          margin-top: 4px;
          word-break: break-all;
        }
        .no-data {
          font-weight: 500;
          font-size: 14px;
          color: var(--dark-4);
          letter-spacing: 0.4px;
          line-height: 20px;
          margin-top: 4px;
        }
      }
    }
  }
</style>
