<template>
  <artmisads-modal
    :open="true"
    title="IM Contact Info"
    :width="720"
    :onCancel="() =>  props.setShowModal(false)"
    :destroyOnClose="true"
  >
    <a-spin :spinning="spinning">
      <div class="userImInfoModal">
        <div class="item">
          <a-form>
            <a-form-item
              name="wechat"
              v-bind="validateInfos.wechat"
            >
              <div class="input-item">
                <span class="text">Wechat</span>
                <artmisads-input maxlength="100" style="width: 324px;" @blur="() => validate('wechat')" size="middle" v-model:value="modelRef.wechat"/>
              </div>
            </a-form-item>
          </a-form>
          <a-form>
            <a-form-item
              name="slack"
              v-bind="validateInfos.slack"
            >
              <div class="input-item">
                <span class="text">Slack</span>
                <artmisads-input maxlength="100" style="width: 324px;" @blur="() => validate('slack')" size="middle" v-model:value="modelRef.slack"/>
              </div>
            </a-form-item>
          </a-form>
        </div>
        <div class="item">
          <a-form>
            <a-form-item
              name="whatsapp"
              v-bind="validateInfos.whatsapp"
            >
              <div class="input-item">
                <span class="text">Whatsapp</span>
                <artmisads-input maxlength="100" style="width: 324px;" @blur="() => validate('whatsapp')" size="middle" v-model:value="modelRef.whatsapp"/>
              </div>
            </a-form-item>
          </a-form>
          <a-form>
            <a-form-item
              name="feishu"
              v-bind="validateInfos.feishu"
            >
              <div class="input-item">
                <span class="text">Feishu</span>
                <artmisads-input maxlength="100" style="width: 324px;" @blur="() => validate('feishu')" size="middle" v-model:value="modelRef.feishu"/>
              </div>
            </a-form-item>
          </a-form>
        </div>
        <div class="item">
          <a-form>
            <a-form-item
              name="dingtalk"
              v-bind="validateInfos.dingtalk"
            >
              <div class="input-item">
                <span class="text">Dingtalk</span>
                <artmisads-input maxlength="50" style="width: 324px;" @blur="() => validate('dingtalk')" size="middle" v-model:value="modelRef.dingtalk"/>
              </div>
            </a-form-item>
          </a-form>
        </div>
      </div>
    </a-spin>
    <template #footer>
      <div class="btn">
        <artmisads-button type="primary" @click="postUserIm">Save</artmisads-button>
      </div>
    </template>
  </artmisads-modal>
</template>

<script setup>
import moment from 'moment';
import { Form } from 'ant-design-vue';
import { ref, reactive, onMounted } from "vue";
import { connectionApi } from "@/server/seller/index";

const props = defineProps(["userImInfo", 'setShowModal']);
const spinning = ref(false);

const useForm = Form.useForm;
  const modelRef = reactive({
    wechat: props.userImInfo.wechat,
    slack: props.userImInfo.slack,
    whatsapp: props.userImInfo.whatsapp,
    feishu: props.userImInfo.feishu,
    dingtalk: props.userImInfo.dingtalk,
  });

  const rulesRef = reactive({
    wechat: [{
      required: false,
    }],
    slack: [{
      required: false,
    }],
    whatsapp: [{
      required: false,
    }],
    feishu: [{
      required: false,
    }],
    dingtalk: [{
      required: false,
    }],
  });

  const { validate, validateInfos } = useForm(modelRef, rulesRef);

const postUserIm = async () => {
  const validateRes = await validate();
  if (!validateRes.errorFields) {
    spinning.value = true;
    let paramsData = validateRes;
    const res = await await connectionApi.postUserIm(paramsData);
    const { success } = res;
    if (success) {
      props.setShowModal(false, true);
    }
    spinning.value = false;
  }
};

</script>

<style lang="less" scoped>
.userImInfoModal {
  padding-bottom: 56px;
  .item {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
    &:first-child {
      margin-top: 8px;
    }
    :deep(.ant-form) {
      .input-item {
        display: flex;
        flex-direction: column;
        width: 324px;
        .text {
          color: var(--dark-2);
          font-weight: 500;
          line-height: 18px;
          font-size: 13px;
          margin-bottom: 4px;
          display: flex;
          > span {
            color: var(--red-text);
            margin-left: 3px;
            font-size: 14px;
            font-weight: 600;
            line-height: 18px;
          }
        }
      }
      .ant-form-item {
        margin-bottom: 0;
      }
    }
  }
}
</style>
