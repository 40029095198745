<template>
  <artmisads-modal
    :open="true"
    title="Tax Information"
    :width="720"
    :onCancel="() => props.setShowModal(false)"
    :destroyOnClose="true"
  >
    <a-spin :spinning="spinning">
      <div class="taxInfoModal">
        <div class="item">
          <p>W8Ben From</p>
          <div class="item-wrap">
            <div class="file-name" v-if="w8beanFromValueStr">
              <div>
                <svg class="icon icon-pdf" aria-hidden="true">
                  <use xlink:href="#icon-pdf"></use>
                </svg>
                <span class="name">{{w8beanFromValueStr}}</span>
              </div>
              <svg class="icon close-cu" aria-hidden="true" @click="() => clearFile('w8bean')">
                <use xlink:href="#icon-close-cu"></use>
              </svg>
            </div>
            <div class="upload-wrap" v-else>
              <a-upload
                name="avatar"
                :show-upload-list="false"
                list-type="picture-card"
                :before-upload="beforeUpload"
                @change="data => handleChange(data, 'w8bean')"
                
              >
                <svg class="icon shangchuan2" aria-hidden="true">
                  <use xlink:href="#icon-jiahao-cu"></use>
                </svg>
                Upload
              </a-upload>
            </div>
            <a target="_blank" href="http://www.irs.gov/pub/irs-pdf/fw8ben.pdf">http://www.irs.gov/pub/irs-pdf/fw8ben.pdf</a>
          </div>
        </div>
        <div class="item">
          <p>W9 From</p>
          <div class="item-wrap">
            <div class="file-name" v-if="w9beanFromValueStr">
              <div>
                <svg class="icon icon-pdf" aria-hidden="true">
                  <use xlink:href="#icon-pdf"></use>
                </svg>
                <span class="name">{{w9beanFromValueStr}}</span>
                <svg class="icon close-cu" aria-hidden="true" @click="() => clearFile('w9bean')">
                  <use xlink:href="#icon-close-cu"></use>
                </svg>
              </div>
            </div>
            <div class="upload-wrap" v-else>
              <a-upload
                name="avatar"
                list-type="picture-card"
                :show-upload-list="false"
                @change="data => handleChange(data, 'w9bean')"
              >
                <svg class="icon shangchuan2" aria-hidden="true">
                  <use xlink:href="#icon-jiahao-cu"></use>
                </svg>
                Upload
              </a-upload>
            </div>
            <a href="http://www.irs.gov/pub/irs-pdf/fw9.pdf" target="_blank">http://www.irs.gov/pub/irs-pdf/fw9.pdf</a>
          </div>
        </div>
      </div>
    </a-spin>
    <template #footer>
      <div class="btn">
        <artmisads-button type="primary" @click="publisherProfileSaveOrUpdate">Save</artmisads-button>
      </div>
    </template>
  </artmisads-modal>
</template>

<script setup>
import { message } from 'ant-design-vue';
import { ref, onMounted, computed } from "vue";
import { publisherApi } from "~/server";

const w8beanFromValue = ref('');
const w9beanFromValue = ref('');
const props = defineProps(["accountInfo", 'setShowModal', 'countryList']);
const spinning = ref(false);

const w8beanFromValueStr = computed(() => {
  let str = '';
  if (w8beanFromValue.value) {
    str = w8beanFromValue.value.slice(0,24);
    str += '...pdf';
  }
  return str;
})

const w9beanFromValueStr = computed(() => {
  let str = '';
  if (w9beanFromValue.value) {
    str = w9beanFromValue.value.slice(0,24);
    str += '...pdf';
  }
  return str;
})


const handleChange = async ({file}, dataType) => {
  const { type, originFileObj, percent, name } = file;
  let form = new FormData();
  form.append('file', originFileObj);
  if (percent === 100) {
    if (type !== 'application/pdf') {
      return message.error('Please upload a pdf file.');
    } else {
      spinning.value = true;
      let res = await publisherApi.publisherProfileUploadPDF(form);
      const { success, data } = res;
      if (success) {
        if (dataType === 'w8bean') {
          w8beanFromValue.value = data;
        } else if (dataType === 'w9bean') {
          w9beanFromValue.value = data;
        }
      }
      spinning.value = false;
    }
  }
}

onMounted(() => {
  if (props.accountInfo) {
    w8beanFromValue.value = props.accountInfo.w8beanFrom;
    w9beanFromValue.value = props.accountInfo.w9beanFrom;
  }
})

const clearFile = dataType => {
  if (dataType === 'w8bean') {
    w8beanFromValue.value = '';
  } else if (dataType === 'w9bean') {
    w9beanFromValue.value = '';
  }
}

const publisherProfileSaveOrUpdate = async () => {
  spinning.value = true;
  let paramsData = {
    w8beanFrom: w8beanFromValue.value,
    w9beanFrom: w9beanFromValue.value
  };
  const res = await await publisherApi.publisherProfileTaxSaveOrUpdate(paramsData);
  const { success } = res;
  if (success) {
    props.setShowModal(false, true);
  }
  spinning.value = false;
};

</script>

<style lang="less" scoped>
.taxInfoModal {
  padding-bottom: 24px;
  .item {
    margin-top: 24px;
    &:first-child {
      margin-top: 8px;
    }
    > p {
      color: var(--dark-2);
      font-weight: 500;
      font-size: 13px;
      letter-spacing: 0.3px;
      line-height: 18px;
    }
  }
  .file-name {
    display: flex;
    justify-content: space-between;
    width: 325px;
    height: 32px;
    cursor: pointer;
    background: var(--big-bg-1);
    border-radius: 8px;
    color: var(--dark-2);
    margin-top: 3px;
    padding: 6px 16px;
    &:hover {
      background: var( --bg-gray-2);
    }
    > div {
      display: flex;
      height: 28px;
      border-radius: 8px;
      align-self: center;
      // &:hover {
      //   background-color: var(--bg-gray-4);
      // }
      .icon-pdf {
        width: 20px;
        height: 20px;
        fill: var( --primary-text);
        align-self: center;
      }
      .name {
        color: var( --primary-text);
        font-weight: 500;
        font-size: 14px;
        letter-spacing: 0.4px;
        line-height: 20px;
        margin-left: 8px;
        margin-right: 24px;
        align-self: center;
      }
    }
    .close-cu {
      cursor: pointer;
      width: 24px;
      height: 24px;
      padding: 4px;
      align-self: center;
      fill: var(--dark-3);
      &:hover {
        fill: var(--dark-1);
      }
    }
  }
  .item-wrap {
    display: flex;
    > a {
      color: var(--dark-3);
      font-weight: 400;
      font-size: 13px;
      letter-spacing: 0.3px;
      line-height: 18px;
      margin-left: 12px;
      align-self: flex-end;
      text-decoration: underline;
    }
  }
  
  .upload-wrap {
    display: flex;
    // justify-content: space-between;
    :deep(.ant-upload-wrapper){
      margin-top: 3px;
      width: 325px;
      .ant-upload.ant-upload-select {
        margin: 0;
        width: 325px;
        height: 32px;
        color: var(--dark-2);
        background: var(--big-bg-1);
        border: 1px dashed var(--border-1);
        border-radius: 8px;
        font-size: 14px;
        font-weight: 500;
        &:hover {
          background: var( --bg-gray-2);
          border: 1px dashed var(--bg-gray-7);
        }
        svg {
          width: 20px;
          height: 20px;
          fill: var(--dark-2);
          margin-right: 8px;
        }
      }
    }
  }
}
</style>
