<template>
  <div class="account">
    <div class="head">
      <p>Tax Information<span v-if="!props.accountInfo.w8beanFrom || !props.accountInfo.w9beanFrom">*</span></p>
      <artmisads-select-button @click="() => setShowModal(true)" size="middle" :showEditIcon="true">Update Tax Info</artmisads-select-button>
    </div>
    <div class="cont">
      <div class="item">
        <span class="name">W8Ben From:</span>
        <span class="text" v-if="w8beanFromValueStr">
          <span class="pdf-icon">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-pdf"></use>
            </svg>
            <a :href="props.accountInfo.w8beanFrom" target="_blank">{{w8beanFromValueStr}}</a>
          </span>
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-duigou-jiacu"></use>
          </svg>
        </span>
        <span class="no-data" v-else>--</span>
      </div>
      <div class="item">
        <span class="name">W9 From:</span>
        <span class="text" v-if="w9beanFromValueStr">
          <span class="pdf-icon">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-pdf"></use>
            </svg>
            <a :href="props.accountInfo.w9beanFrom" target="_blank">{{w9beanFromValueStr}}</a>
          </span>
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-duigou-jiacu"></use>
          </svg>
        </span>
        <span class="no-data" v-else>--</span>
      </div>
    </div>
    <editTaxInfoModal
      :accountInfo="props.accountInfo"
      :setShowModal="setShowModal"
      :countryList="countryList"
      v-if="showModal"
    />
  </div>
</template>
<script setup>
  import { ref, computed } from 'vue';
  import editTaxInfoModal from './editTaxInfoModal.vue';
  const props = defineProps(['accountInfo', 'getData', 'countryList', 'countryListMap'])

  const showModal = ref(false);

  const w8beanFromValueStr = computed(() => {
    let str = '';
    if (props.accountInfo.w8beanFrom) {
      str = props.accountInfo.w8beanFrom.slice(0,55);
      str += '...pdf';
    }
    return str;
  })

  const w9beanFromValueStr = computed(() => {
    let str = '';
    if (props.accountInfo.w9beanFrom) {
      str = props.accountInfo.w9beanFrom.slice(0,55);
      str += '...pdf';
    }
    return str;
  })

  const setShowModal = (status, reload) => {
    showModal.value = status;
    if (reload) {
      props.getData()
    }
  }

</script>
<style lang="less" scoped>
  .account {
    padding: 16px 0 24px 16px;
    overflow: hidden;
    border-radius: 12px;
    background-color: var(--color-white);
    box-shadow: var(--section-box-shadow-6);
    .head {
      padding: 0 16px 16px 0;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid var(--border-4);
      > p {
        font-weight: 600;
        font-size: 20px;
        color: var(--dark-1);
        letter-spacing: 0.5px;
        line-height: 28px;
        > span {
          color: var(--red-text);
          margin-left: 3px;
          font-size: 16px;
          font-weight: 600;
          line-height: 32px;
        }
      }
    }
    .cont {
      display: flex;
      flex-direction: column;
      .item {
        display: flex;
        flex-direction: column;
        padding-right: 16px;
        margin-top: 24px;
        .name {
          font-weight: 400;
          font-size: 13px;
          color: var(--dark-3);
          letter-spacing: 0.3px;
          line-height: 18px;
        }
        .text {
          font-weight: 600;
          font-size: 14px;
          color: var(--dark-1);
          letter-spacing: 0.4px;
          line-height: 20px;
          margin-top: 4px;
          word-break: break-all;
          display: flex;
          justify-content: space-between;
          .pdf-icon {
            display: flex;
            > a {
              color: var(--dark-1);
              text-decoration: underline;
            }
            svg {
              width: 20px;
              height: 20px;
              fill: var(--dark-1);
              margin-right: 8px;
              align-self: center;
            }
          }
          > svg {
            width: 20px;
            height: 20px;
            align-self: center;
            fill: var(--green-5);
          }
        }
        .no-data {
          font-weight: 500;
          font-size: 14px;
          color: var(--dark-4);
          letter-spacing: 0.4px;
          line-height: 20px;
          margin-top: 4px;
        }
      }
    }
  }
</style>
